import React from 'react';
import logo from './logo.svg';

import './App.css';

function App() {
  const lslogoHref = "https://librestream.com/wp-content/themes/librestream/static/images/logo.png";

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={lslogoHref} className="ls-logo" alt="ls_logo" href="https://librestream.com/" />
        <p>
          Onsight  Network Test:<br></br>
          <div > HTTPS connectivity successful.</div>
          <br></br>
          Onsight hosted services should be accessible from your location.
          <br></br>
        </p>
        <p>To access training and support log into  <a
          className="App-link"
          href="https://onsight.librestream.com/"
          target="_blank"
          rel="noopener noreferrer"
        > Onsight Platform Manager</a>  .</p>

        <a className="App-sub-link"
          href="https://librestream.com/"
          target="_blank"
          rel="noopener noreferrer"
        > Librestream</a>
      </header>
    </div>
  );
}

export default App;
